export default [
  {
    title: 'Dashboard',
    icon: 'PieChartIcon',
    route: 'dashboard',    
    permissionCode:'DB001'
  },
]


