export default [
  {
    title: 'Accounting',
    icon: 'DollarSignIcon',    
    children: [
      {
        title: 'เบิกค่าเดินทาง',
        route: 'accounting', 
        icon: 'ClipboardIcon',
        permissionCode:'AC001',
      },
      {
        title: ' Expenses Report by Employee',
        route: 'accounting-admin', 
        icon: 'PackageIcon',
        permissionCode:'AC002',
      }
    ]            
  },
]


