<template>
  <div>
    <b-modal
      ref="change-password-modal"
      id="change-password-modal"
      title="เปลี่ยน Password"
      size="sm"
      hide-footer
    >
      <b-card-text>
        <label>Password</label>
        <b-form-input id="smallInput" size="sm" placeholder="Password" class="mb-1" v-model.trim="password" />
        <small class="text-danger">{{ validatePassword(password) }}</small>
       
        <!--- START (BTN SUBMIT) --->
        <b-row class="mt-2">
          <b-col md="12" class="d-flex justify-content-end">
            <b-button variant="primary" size="sm" @click="save" :disabled="!password || validatePassword(password, 'boolean')">
            <BSpinner v-if="isLoading"
              small              
            />
              <span v-else>บันทึก</span>
            </b-button>
          </b-col>
        </b-row>
        <!--- END (BTN SUBMIT) --->
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  VBModal,
  BCardText,
  BRow,
  BCol,
  BFormInput,  
  BFormRadioGroup,
  BFormGroup,
  BButton, 
  BSpinner
} from "bootstrap-vue";


export default { 
  props:['isLoading'],
  components: {    
    BCardText,
    BRow,
    BCol,
    BFormInput,    
    BFormRadioGroup,
    BFormGroup,
    BButton,
    BSpinner
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      password:""
    };
  },
  watch: {
    isLoading(newValue, oldValue) {
      if (!newValue) {
        this.password = ""
        this.$refs["change-password-modal"].hide();
      }
    }
  },
     

  methods: {
    validatePassword(password, type="string") {
      const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{6,}$/;      
      const validated =  re.test(password);
      if(password && !validated){
        if(type=="boolean"){
          return true
        }else{
          return "** ต้องมี a-z A-Z ตัวอักขระพิเศษ ตัวเลขและมากกว่า 6 ตัว"
        }
        
      }
    },
    save() {
      if(!this.password){
        alert("กรุณากรอก Password ที่ต้องการเปลี่ยน")
        return
      }
      this.$emit("submit", this.password)
      
      
      
    },
  },
};
</script>

<style>

</style>
